import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c89ed6b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "accordion" }
const _hoisted_2 = ["data-bs-target", "aria-controls"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["header", { isOpen: _ctx.isOpen }]),
      role: "button",
      "data-bs-toggle": "collapse",
      "data-bs-target": `#${_ctx.id}`,
      "aria-expanded": "false",
      "aria-controls": _ctx.id,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.headerClick && _ctx.headerClick(...args)))
    }, [
      _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
      _createVNode(_component_Icon, {
        path: _ctx.mdiPlus,
        height: 30,
        width: 30
      }, null, 8, ["path"])
    ], 10, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass(["collapse", { show: _ctx.isOpen }]),
      id: _ctx.id
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 10, _hoisted_3)
  ]))
}